import { render, staticRenderFns } from "./ResellerOrders.vue?vue&type=template&id=00ad6033&scoped=true"
import script from "./ResellerOrders.vue?vue&type=script&lang=js"
export * from "./ResellerOrders.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00ad6033",
  null
  
)

export default component.exports